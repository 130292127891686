import { get, post, patch } from '../utils/api';
import type { NoticeToVacate } from '../containers/ResidentProfile/types';
import type { HouseholdTransferLeaseValidations } from '../utils/affordable';

const { apiURL, requestToDownload, postDownload } = require('../utils/api');

const request = require('../utils/api').default;

class LeaseService {
  saveNoticeToVacate(
    organizationId: string,
    propertyId: string,
    ntv: NoticeToVacate,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(ntv),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${ntv.leaseId}/ntv`,
      options,
    );
  }
  deleteNoticeToVacate(
    organizationId: string,
    propertyId: string,
    ntv: NoticeToVacate,
  ) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${ntv.leaseId}/ntv`,
      options,
    );
  }
  generateLeaseDocument(lease: Object) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return requestToDownload(`${apiURL}/lease/${lease.id}/generate`, options);
  }

  generateHUDLeaseDocument(
    affordableQualificationId: string,
    options?: Object,
  ) {
    const headers = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return requestToDownload(
      `${apiURL}/applications/${affordableQualificationId}/HUD/generate`,
      headers,
    );
  }

  generate811HUDLeaseDocument(
    affordableQualificationId: string,
    options?: Object = {},
  ) {
    const headers = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return requestToDownload(
      `${apiURL}/affordable-qualifications/${affordableQualificationId}/811HUD/generate`,
      headers,
    );
  }

  updateFasReadiness(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    fasReady: boolean,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fasReady }),
    };
    return request(`${apiURL}/lease/${leaseId}/update`, options);
  }

  updateHasCommercialOptions(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    hasCommercialOptions: boolean | null,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hasCommercialOptions }),
    };
    return request(`${apiURL}/lease/${leaseId}/update`, options);
  }

  updateReceivesHousingAssistance(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    receivesHousingAssistance: boolean | null,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ receivesHousingAssistance }),
    };
    return request(`${apiURL}/lease/${leaseId}/update`, options);
  }

  generateRenewalLeaseDocument(lease: Object) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return requestToDownload(
      `${apiURL}/lease/${lease.id}/generateRenewal`,
      options,
    );
  }
  generateTransferLeaseDocument(lease: Object) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return requestToDownload(
      `${apiURL}/lease/${lease.id}/generateTransfer`,
      options,
    );
  }
  deleteMonthlyTransaction(
    organizationId: string,
    propertyId: string,
    leaseMonthlyTransactionId: string,
  ) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease-monthly-transaction/${leaseMonthlyTransactionId}`,
      options,
    );
  }

  getMonthlyLeaseTransactionsForResident(leaseId: string, options?: Object) {
    return get(`/lease/${leaseId}/leaseMonthlyTransactionResident`, options);
  }

  getMonthlyRequiredFeesForLease(leaseId: string, options?: Object) {
    return get(`/lease/${leaseId}/lease-monthly-required-fees`, options);
  }

  updateMonthlyRequiredFeesForLease(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    requiredMonthlyFees: Array<Object>,
    removedMonthlyFees: Array<Object>,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ requiredMonthlyFees, removedMonthlyFees }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/lease-monthly-required-fees`,
      options,
    );
  }

  getLeaseHoldersSignatureStatus(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/leases/${leaseId}/signatures/status`,
      options,
    );
  }

  cancelLease(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/cancel`,
      null,
      options,
    );
  }

  sendToPortal(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/send-to-portal`,
      null,
      options,
    );
  }

  executeLease(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    signature: string,
    options?: Object,
  ) {
    const body = {
      sendToDocuments: true,
      showSignatures: true,
      landlord: {
        signature,
      },
    };
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/execute`,
      JSON.stringify(body),
      options,
    );
  }

  uploadLease(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    document: Object,
    options?: Object,
  ) {
    /**
     * Have to make this requestOptions object to override the way that
     * currently POST is forcing Content-Type to be application/json.
     */
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/upload`,
      document,
      requestOptions,
    );
  }

  generateCommercialLeaseAbstract(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    fileName: string,
    options?: Object,
  ) {
    return postDownload(
      `/${organizationId}/${propertyId}/lease/${leaseId}/abstract?fileName=${fileName}`,
      fileName,
      '{}',
      options,
    );
  }

  getRenewalLeaseProrates(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/lease/${leaseId}/renewal-prorates`,
      options,
    );
  }

  validateHouseholdTransferLease(
    organizationId: string,
    propertyId: string,
    householdId: string,
  ): HouseholdTransferLeaseValidations {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return get(
      `/${organizationId}/${propertyId}/household/${householdId}/validate-transfer-lease`,
      options,
    );
  }

  getLeaseRentSteps(leaseId: string) {
    return get(`/lease/${leaseId}/lease-rent/steps`);
  }

  saveLeaseRentSteps(
    leaseId,
    rentSteps,
    rentStepsToDelete,
    rentStepsToUpdate,
    rentStepsToCreate,
  ) {
    return patch(
      `/lease/${leaseId}/lease-rent/steps`,
      JSON.stringify({
        rentSteps,
        rentStepsToDelete,
        rentStepsToUpdate,
        rentStepsToCreate,
      }),
    );
  }
}

export default LeaseService;
